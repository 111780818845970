@import '~@which/seatbelt/src/styles/styles';

.sticky {
  position: fixed;
  bottom: $sb-spacing-l;
  display: flex;
  justify-content: center;
  transition: all 0.2s ease-in-out;
  transform: translate(calc(50vw - 50%), 150%);
  z-index: 20;
}

.visible {
  transform: translate(calc(50vw - 50%), 0);
}

.infoBar {
  padding: $sb-spacing-l;
  display: flex;
  flex-direction: row;
  width: 320px;
  align-items: center;
  justify-content: space-between;
  border-radius: $sb-border-radius-s;
  background: $sb-colour-background-brand-blue;

  @include sb-respond-to(medium-up) {
    width: 330px;
  }
}

.error {
  background: $sb-colour-background-negative;
}

.textAndLink {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.link {
  padding-left: $sb-spacing-s;
}

.link,
.link:hover,
.link:visited {
  color: $sb-colour-text-default;
}

.close {
  justify-self: flex-end;
  padding: 0 0 0 $sb-spacing-s;
  cursor: pointer;
}
