@import '~@which/seatbelt/src/styles/styles';

// Do not make a module file
// Supports global CSS selectors from glide templates
// article-wrapper class lives here: src/shared/renderers/article-body-renderer.tsx

@mixin stickycomponent {
  position: sticky;
  top: 32px;
}

// Content from RTE in glide for an article details page
.article-wrapper {
  overflow-wrap: break-word;

  .h2-article,
  .h3-article {
    > .span-article {
      font-size: inherit;
    }
  }

  .h1-article,
  .h2-article,
  .h3-article {
    .w-article-body-image-news:only-child {
      margin: 0;
    }
  }

  .h1-article,
  .h2-article,
  .h3-article,
  .h4-article,
  .h5-article,
  .h6-article {
    &:empty {
      display: none;
    }
  }

  .h3-article,
  .h4-article {
    margin: $sb-spacing-m 0;

    @include sb-respond-to(medium-up) {
      margin: $sb-spacing-xl 0 $sb-spacing-l;
    }
  }

  > div:first-child ~ .h2-article:first-of-type {
    margin-top: 0;
  }

  .h2-article {
    &:first-child {
      margin-top: 0;
    }

    margin: $sb-spacing-xl 0 $sb-spacing-m;

    @include sb-respond-to(medium-up) {
      margin: $sb-spacing-2xl 0 $sb-spacing-l;
    }

    > .number-text {
      color: $sb-colour-text-brand;
      font-size: inherit;
      font-family: inherit;
    }

    strong {
      font-family: inherit;
    }
  }

  .w-infogram-content-wrapper {
    margin: $sb-spacing-l 0;
  }
  pre {
    text-wrap: initial;
  }

  .p-article,
  .ol-article,
  .ul-article {
    margin: $sb-spacing-m 0;
  }

  .ol-article,
  .ul-article {
    padding: 0;
    position: relative;
  }

  .li-article {
    padding: $sb-spacing-m 0 0 30px;
    position: relative;

    &::before {
      left: 0;
      position: absolute;
    }
  }

  .ol-article {
    counter-reset: item;
    list-style-type: none;

    .li-article::before {
      font-weight: 600; // What do we do here to handle theme toggle?
      content: counter(item) '  ';
      counter-increment: item;
      color: $sb-colour-text-brand;
    }
  }

  .ul-article {
    list-style-type: none;

    .li-article::before {
      display: inline-block;
      width: 10px;
      height: 10px;
      content: '';
      background-color: $sb-colour-background-brand-red;
      top: 25px;
    }
  }

  // Fixes any p tags that come through in table cells via RTE
  table {
    th,
    td {
      .p-article {
        @include sb-font-size-line-height(16px, 20px);

        margin: 0 0 $sb-spacing-s;

        &:last-of-type {
          margin: 0;
        }
      }
    }
  }

  .youtube-wrapper,
  .vimeo-video-wrapper,
  .brightcove-wrapper {
    margin: $sb-spacing-l -7.6%;

    @include sb-respond-to(medium-up) {
      margin-right: 0;
      margin-left: 0;
    }

    @include sb-respond-to(large) {
      margin: $sb-spacing-xl 0;
    }
  }

  section.table-notes {
    p,
    span {
      font-size: inherit;
      line-height: inherit;
    }
  }

  > :first-child {
    margin-top: 0;
  }

  a[href^='tel'] {
    color: $sb-colour-text-link-default;
  }
}

// Article grid layout over-rides
// Layout comes from here: src/shared/grids/article/Grid/GridArticle.module.scss
.gridarea-related {
  display: none;

  @include sb-respond-to(large) {
    display: revert;
  }

  .sidebar {
    @include sb-respond-to(large) {
      @include stickycomponent();
      margin-bottom: $sb-spacing-l;
    }

    // Maintains 300px RHS column width for small DFP ad banner
    @include sb-respond-to(xlarge) {
      min-width: 300px;
      display: flex;
      flex-flow: column nowrap;
      align-items: flex-start;
    }

    .promo-block {
      margin-bottom: $sb-spacing-l;
    }

    // Special DFP overrides - hide the DFP on resize under xlarge
    .article-dfp-container {
      display: none;

      @include sb-respond-to(xlarge) {
        display: block;
      }
    }
  }
}

.gridarea-body {
  .sharing-options-vertical {
    margin: $sb-spacing-xl 0;
  }

  .promo-block {
    margin-top: $sb-spacing-l;

    @include sb-respond-to(large) {
      margin-top: $sb-spacing-xl;
    }
  }

  // @TODO: Find a nicer way to handle this logic (also if it's still relevant)
  // This all happens on article pages and is to do with showing/hiding content
  // right sidebar vs content under article body
  .related-footer {
    margin-top: $sb-spacing-l;

    @include sb-respond-to(medium-up) {
      margin-top: $sb-spacing-xl;
      display: flex;
      gap: 6%;

      // We show AssociatedArticles both under article and in right sidebar
      > *:not(.associated-articles) {
        display: none;
      }

      .hide-on-scroll {
        visibility: visible;
        opacity: 1;
        flex-grow: 1;
        flex-basis: 47%;
      }
    }

    .associated-articles {
      margin-bottom: $sb-spacing-xl;
    }
  }
}

.gridarea-share {
  @include sb-respond-to(medium-up) {
    @include stickycomponent();
    max-height: calc(100vh - 10px);
  }
}

// Special DFP overrides
.article-dfp-container {
  &:first-child {
    margin: $sb-spacing-l 0;
  }
}

.vertical-dfp-container {
  text-align: center;
  padding: $sb-spacing-l 0 $sb-spacing-m;
}

.localFooter {
  margin-top: $sb-spacing-2xl;
}

// Print styling
@include sb-respond-to(print) {
  #money-newsletter-sign-up,
  #onetrust-consent-sdk,
  .article-dfp-container,
  .JFE,
  .QSIFeedbackButton {
    display: none;
  }
}
